import React from "react";
import '../App.css'
function Inventors(props) {
    return (
        <div className="col pt-3">
            <h5 class="card-title text-light" align="center">Inventor's Details</h5>
            <div align="center">
                <hr className="text-light w-25 mb-5 mt-3"></hr>
            </div>
            <div className="container text-light">
                <div className="row">
                    <div className="col" align="center">
                        <text className="fs-6">Dr. Mohd. Rizwan</text><br />
                        <text className="fs-7">Assistant Professor at Kumaun University Nainital, Uttarakhand, India</text><br />
                        <text className="fs-7">Author and Translator</text>
                    </div>
                    <div className="col" align="center">
                        <text className="fs-6">Mr. Fareed Ahmad</text><br />
                        <text className="fs-7">Instructor at NIELIT, India</text><br />
                        <text className="fs-7">Author, Translator, Editor and Publisher</text>
                    </div>
                    <div className="col" align="center">
                        <text className="fs-6">Dr. Asif Mian Ansari</text><br />
                        <text className="fs-7">Consultant Surgeon at Vishwakarma Multispecialty Hospital, Chandausi, U.P. India</text><br />
                        <text className="fs-7">Author and Researcher</text>
                    </div>
                    <div className="col" align="center">
                        <text className="fs-6">Mrs. Nisha Jabeen</text><br />
                        <text className="fs-7">Author and Translator</text>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inventors;
