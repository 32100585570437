// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDc0SdKhl60s8t80fMjVqyC8KQ2P1suU2M",
  authDomain: "ultimate-browser-388f4.firebaseapp.com",
  databaseURL: "https://ultimate-browser-388f4-default-rtdb.firebaseio.com",
  projectId: "ultimate-browser-388f4",
  storageBucket: "ultimate-browser-388f4.appspot.com",
  messagingSenderId: "348243978305",
  appId: "1:348243978305:web:8ea91c88e306c6ba63bec0",
  measurementId: "G-5BPKSW9R8L"
};

console.log("initialize");
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
