import React from "react";
import '../App.css'
function DownloadOption(props) {
  return (
    <div className="row mt-2">
      <div className="col-6 d-flex  align-content-center">
        <div>
          <div className="row mt-2">
            <img src={process.env.PUBLIC_URL + '/images/apple.png'} alt="loading" />
          </div>
          <div className="row mt-4" align="center">
            <button className="btn btn-link link-dark" onClick={props.dFM}>Download (For Mac)</button>
          </div>
        </div>
      </div>
      <div className="col-6 d-flex  align-content-center">
        <div>
          <div className="row mt-2">
            <img src={process.env.PUBLIC_URL + '/images/windows.png'} alt="loading" />
          </div>
          <div className="row mt-4" align="center">
            <button className="btn btn-link link-dark" onClick={props.dFW}>Download (For Win)</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadOption;
