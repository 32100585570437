import React from "react";
import DownloadOption from "../DownloadOption/DownloadOption";
function Discription(props) {
  return (
    <div className="col mt-5">
      <h1 className="display-6 text-dark mt-5">
        {props.desc[0]}
      </h1>
      <ul>
        {getList(props.desc)}
      </ul>
      <div className="row mt-5 d-flex align-items-center">
        <DownloadOption dFW={props.dFW} dFM={props.dFM} />
      </div>
    </div>
  );
}
export default Discription;

const getList = data => {
  let content = [];
  for (let i = 1; i < data.length; i++) {
    content.push(<li>{data[i]}</li>);
  }
  return content;
};
