import React from "react";

import Inventors from "../Inventors/Inventors";
function Footer() {
  return (
    <footer class="page-footer font-small bg-dark pt-4">

      <div class="container">

     
      <Inventors />

      
      <hr className="text-light my-4 mx-5 "></hr>

        <ul class="list-unstyled list-inline text-center py-2">
          <li class="list-inline-item">
            <h5 class="text-light">For More Details Contact Us: </h5>
          </li>
        </ul>
        <ul class="list-unstyled list-inline text-center py-2">
          <li class="list-inline-item">
            <h5 class="text-light"><a href="info@fox-browser.com" className="link-light">contact@mohdrizwan.com</a></h5>
          </li>
        </ul>
      </div>
      <div class="footer-copyright text-center py-3 text-light"><text>© 2020 Copyright: </text>
        <a href="https://fox-browser.com/" className="link-light">fox-browser.com</a>
      </div>
      
    </footer>
  );
}

export default Footer;
