import React from "react";
import Discription from "./Description/Description";
import ProductDetails from "./ProductDetails/ProductDetails";
import PatentDetails from "./PatentDetails/PatentDetails";
import Footer from "./Footer/Footer";
import './App.css';
import firebase from './firebase';

function App() {
  const ref = firebase.firestore().collection("web-resources");
  const storage = firebase.storage();
  const [resources, setRes] = React.useState([])

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await ref.get()
      setRes(data.docs.map(doc => doc.data()))
    }
    fetchData()
  }, []);

  function downloadBlob(blobUrl, name) {
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  function viewPdf(pdfUrl, name) {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = name;

    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  const downloadForMac = () => {
    storage.ref('software/foxbrowser.dmg').getDownloadURL().then((url) => {
      downloadBlob(url, 'foxbrowser.dmg');
    })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }

  const downloadForWin = () => {
    storage.ref('software/Fox Browser.exe').getDownloadURL().then((url) => {
      downloadBlob(url, 'foxbrowserInstaller.exe');
    })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }

  const downloadProposal = () => {
    storage.ref('pdfs/Proposal.pdf').getDownloadURL().then((url) => {
      viewPdf(url, 'Proposal.pdf');
    })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }

  const downloadPatent = () => {
    storage.ref('pdfs/Published-Patent.pdf').getDownloadURL().then((url) => {
      viewPdf(url, 'Published Patent.pdf');
    })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }

  const pt = ['Title of the invention : CLOUD BASED STORAGE SYSTEM FOR MANAGING BROWSING HISTORY AND DATA OF PRIVATE BROWSING SESSION',
    'Application No.202111032702 A',
    'ISSUE NO.  34/2021',
    'DATE:  20/08/2021',
    'Patent Office, New Delhi, India']

  return (

    <div className="bg-light">
      <div className="row">
        <div className="col">
          <nav className="navbar navbar-expand-lg navbar-light bg-dark">
            <div className="container-fluid">
              <img src={process.env.PUBLIC_URL + '/images/logo.jpeg'} alt="loading" />
              <a className="navbar-brand ms-3 text-light" href="fox-browser.com">Fox Browser</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col mt-5 d-flex justify-content-center">
            <img src={process.env.PUBLIC_URL + '/images/foxImage.jpg'} alt="loading" />
          </div>
          {
            resources.map(res => (
              <Discription desc={res.desc} dFW={downloadForWin} dFM={downloadForMac} />
            ))
          }
        </div>
      </div>
      <br />
      <hr className="my-4 ms-5 me-5 mt-5 mb-5"></hr>
      <br />
      <div className="container">
        <div className="row bg-light  d-flex align-items-center justify-content-center">
          {
            resources.map(res => (
              <ProductDetails pdt={res.pd} dl={downloadProposal} />
            ))
          }
          <PatentDetails ptt={pt} dl={downloadPatent} />
        </div>
      </div>
      <br />
      <br />
      <div className="row mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default App;
