import React from "react";
import '../App.css'
function PatentDetails(props) {
  return (
    <div className="col p-4">
      <h5 className="card-title text-dark p-3" align="center">Patent Details</h5>
      <ul className="list-unstyled text-dark " align="center">
        {getList(props.ptt)}
      </ul>
      <div align="center">
        <button type="button" onClick={props.dl} class="btn btn-outline-danger">View Patent</button>
      </div>

    </div>
  );
}

export default PatentDetails;

const getList = data => {
  let content = [];
  for (let i = 0; i < data.length; i++) {
    content.push(<li>{data[i]}</li>);
  }
  return content;
};
